import {useStaticQuery, graphql} from 'gatsby';
import React from 'react';
import usePrevious from '../hooks/usePrevious';

export const playerContext = React.createContext();

const PlayerContext = ({children}) => {
  const playerRef = React.useRef();
  const [paused, setPaused] = React.useState(true);
  const [nowPlayingTitle, setNowPlayingTitle] = React.useState(true);
  const [isOverlayOpen, setIsOverlayOpen] = React.useState(false);
  const [show, setShow] = React.useState(false);
  const [audioSrc, setAudioSrc] = React.useState(null);
  const [nowPlayingIndex, setNowPlayingIndex] = React.useState(null);
  const [songDuration, setSongDuration] = React.useState(0);
  const [songElapsedTime, setSongElapsedTime] = React.useState(0);

  const data = useStaticQuery(graphql`
    {
      allFile(filter: { extension: { eq: "mp3" } }) {
        edges {
          node {
            publicURL
            name
          }
        }
      }
      projects: allProjectsJson {
        edges {
          node {
            audio {
              file
              id
              title
              description
            }
          }
        }
      }
    }
  `);
  const audioFiles = data.allFile.edges.map(({node}) => node);
  const tracks = data.projects.edges.map(({node}) => ({...node.audio[0]}));
  const [hasInit, setHasInit] = React.useState(false);
  const goToNextSong = () => {
    const nextIndex = (nowPlayingIndex + 1) % tracks.length;
    setSongDuration(0);
    setSongElapsedTime(0);
    setNowPlayingIndex(nextIndex);
  };
  React.useEffect(() => {
    playerRef.current.onloadedmetadata = () => {
      setSongDuration(Math.floor(playerRef.current.duration));
      hasInit && playerRef.current.play();
      hasInit && setPaused(false);
      setHasInit(true);
    };
    const getElapsedTime = () => setSongElapsedTime(Math.floor(playerRef.current.currentTime));
    setInterval(getElapsedTime, 1000);
    return () => clearInterval(getElapsedTime);
  }, [hasInit]);
  const prevAudioSrc = usePrevious(audioSrc);
  React.useEffect(() => {
    if (!audioSrc || !prevAudioSrc) return;
    playerRef.current.load();
  }, [audioSrc, setPaused]);
  React.useEffect(() => {
    if (nowPlayingIndex === null) return;
    setAudioSrc(audioFiles.find(f => f.name === tracks[nowPlayingIndex].id).publicURL);
    setNowPlayingTitle(tracks[nowPlayingIndex].title);
  }, [nowPlayingIndex, tracks, setNowPlayingIndex, setAudioSrc, audioFiles]);
  React.useEffect(() => {
    document.documentElement.style.overflowY = isOverlayOpen ? 'hidden' : 'scroll';
  }, [isOverlayOpen]);
  return (
    <playerContext.Provider
      value={{
        paused,
        setPaused,
        audioSrc,
        setAudioSrc,
        nowPlayingIndex,
        setNowPlayingIndex,
        nowPlayingTitle,
        setNowPlayingTitle,
        audioFiles,
        songDuration,
        setSongDuration,
        songElapsedTime,
        setSongElapsedTime,
        playerRef,
        play: () => playerRef.current.play(),
        show,
        setShow,
        isOverlayOpen,
        setIsOverlayOpen
      }}>
      <audio
        ref={playerRef}
        src={audioSrc}
        type='audio/mp3'
        preload="metadata"
        onEnded={goToNextSong}
      />
      {children}
    </playerContext.Provider>
  );
};

export default PlayerContext;
