module.exports = {
  siteTitle: 'Orestis | Zafeiriou - Composer | Sound Artist', // <title>
  manifestName: 'Orestis | Zafeiriou',
  manifestShortName: 'O | Z', // max 12 characters
  manifestStartUrl: '/',
  manifestBackgroundColor: 'darkslategray',
  manifestThemeColor: 'darkslategray',
  manifestDisplay: 'standalone',
  manifestIcon: 'src/assets/images/FAVICON_0Z.jpg',
  pathPrefix: `/`, // This path is subpath of your hosting https://domain/portfolio
  heading: 'Orestis Zafeiriou',
  subHeading: 'Composer - Sound Artist',

  // social
  socialLinks: [
    {
      icon: 'fa-facebook-f',
      name: 'Facebook',
      url: 'https://facebook.com/orestis.zafeiriou',
    },
  ],
  email: 'orestisdf@gmail.com'
};
