import React from 'react';
import styled from 'styled-components';
import Scroll from './Scroll';
import config from '../../config';
import useScrollDirection from '../hooks/useScrollDirection';
import {playerContext} from '../services/player';

const Nav = styled.nav`
  min-height: 56px;
  font-family: 'Montserrat';
  background: rgba(0, 0, 0, 0.97);
  box-shadow: 0px 0px 2px 3px rgba(47, 79, 79, 0.3);
  position: fixed;
  top: 0;
  right: 0;
  left: 0;
  z-index: 100;
  transition: all 300ms ease-out;
  transform: translateY(${({show}) => show ? '0%' : '-60%'});
  opacity: ${({show}) => show ? 1 : 0};
`;

const Header = () => {
  const [openMenu, setOpenMenu] = React.useState(false);
  const [show, setShow] = React.useState(true);
  const [init, setInit] = React.useState(true);
  const {isOverlayOpen} = React.useContext(playerContext);
  const scrollDirection = useScrollDirection();
  React.useEffect(() => {
    setInit(false);
  }, [])
  React.useEffect(() => {
    if (!scrollDirection) return;
    setShow(!isOverlayOpen && scrollDirection === 'up')
  }, [scrollDirection, isOverlayOpen])
  return (
    <Nav
      className={`navbar navbar-expand-lg`}
      show={show}
      id="mainNav"
      onMouseEnter={() => !show && setShow(true)}
      onMouseLeave={() => (show && !init) && setShow(false)}
      style={{pointerEvents: isOverlayOpen ? 'none' : 'auto'}}
    >
      <div className="container">
        <Scroll
          type="id"
          element="page-top"
          onClick={_ => {
            setShow(false);
            setOpenMenu(!openMenu)
          }}
        >
          <a className="navbar-brand" href="#page-top" style={{fontSize: 20}}>
            Orestis | Zafeiriou
          </a>
        </Scroll>
        <button
          onClick={_ => setOpenMenu(!openMenu)}
          className={`navbar-toggler navbar-toggler-right ${
            openMenu ? '' : 'collapsed'
          }`}
          type="button"
          aria-controls="navbarResponsive"
          aria-expanded={openMenu}
          aria-label="Toggle navigation"
        >
          <i className="fas fa-bars"></i>
        </button>
        <div
          className={`collapse navbar-collapse ${openMenu ? 'show' : ''}`}
          id="navbarResponsive"
        >
          <ul className="navbar-nav ml-auto" style={{fontSize: 18}}>
            <li className="nav-item">
              <Scroll
                onClick={_ => {
                  setShow(false);
                  setOpenMenu(!openMenu)
                }}
                type="id"
                element="about"
              >
                <a className="nav-link" href="#about">
                  About
                </a>
              </Scroll>
            </li>
            <li className="nav-item">
              <Scroll
                 onClick={_ => {
                  setShow(false);
                  setOpenMenu(!openMenu)
                }}
                type="id"
                element="listen"
              >
                <a className="nav-link" href="#listen">
                  Listen
                </a>
              </Scroll>
            </li>
            <li className="nav-item">
              <Scroll
                onClick={_ => {
                  setShow(false);
                  setOpenMenu(!openMenu)
                }}
                type="id"
                element="signup"
              >
                <a className="nav-link" href="#signup">
                  Contact
                </a>
              </Scroll>
            </li>
          </ul>
        </div>
      </div>
    </Nav>
  );
}

export default Header;
